import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Grid, Box, Tooltip } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';

import ReactTimeAgo from 'react-time-ago';
// import TimeAgo from 'javascript-time-ago';
// import pt from 'javascript-time-ago/locale/pt.json';

import { generalTexts } from '../../utils/utils';
import Calendar from "../../assets/calendar.png";
import { CreateModal } from './createModal';
import { ShowModal } from './showModal';

import { useStyles } from './styles';

// TimeAgo.addDefaultLocale(pt)

export default function ListNews({ news = [], addNews = false }) {
  const classes = useStyles();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openShowModal, setOpenShowModal] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);

  function handleOpenModal(notice) {
    setSelectedNotice(notice);
    if (addNews) {
      return setOpenCreateModal(true)
    }

    setOpenShowModal(true)
  }

  function handleClose() {
    setOpenCreateModal(false)
    setOpenShowModal(false)
    setSelectedNotice(null)
  }

  return (
    <>
      <Grid container>
        {!addNews && (
          <Typography className={classes.title} component="h2" variant="subtitle1">
            {generalTexts.newsBoard}
          </Typography>
        )}

        <Grid container spacing={4} alignItems="flex-start">
          {news.length > 0 && (
            news.map((notice, index) =>
              <NoticeCard
                key={index}
                notice={notice}
                onOpenModal={handleOpenModal}
              />
            ))}
          {addNews && <AddNewNotice onOpen={() => setOpenCreateModal(true)} />}
        </Grid>
      </Grid>

      <CreateModal
        notice={selectedNotice}
        open={openCreateModal}
        onClose={handleClose}
      />

      <ShowModal
        notice={selectedNotice}
        open={openShowModal}
        onClose={handleClose}
      />
    </>
  )
}



function NoticeCard({ notice, onOpenModal }) {
  const { TITULO, CONTEUDO, DATA_CADASTRO } = notice
  const classes = useStyles();

  function handleClickNotice() {
    onOpenModal(notice)
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card className={classes.cardNotice} elevation={3} onClick={handleClickNotice}>
        <CardContent className={classes.cardContent}>
          <Typography component="h1" variant="body2">{TITULO}</Typography>
          <Box
            className={classes.noticeContent}
            dangerouslySetInnerHTML={{
              __html: CONTEUDO
            }}
          />
          <Box className={classes.timeAgo}>
            <img src={Calendar} alt="Ícone de calendário" />
            <Box ml={1}>
              <Typography className={classes.positionText} component="h1" variant="subtitle2">
                <ReactTimeAgo date={new Date(DATA_CADASTRO)} />
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}

function AddNewNotice({ onOpen }) {
  const classes = useStyles();

  return (
    <Grid item xs={3} sm={3} md={3} lg={3}>
      <div className={classes.moreButton} onClick={onOpen}>
        <Tooltip title="Cadastrar uma nova notícia">
          <AddOutlined style={{ fontSize: '5rem' }} />
        </Tooltip>
      </div>
    </Grid>
  )
}