/* eslint-disable eqeqeq */
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useContext, useState } from 'react';
import { UserContext } from '../../../providers/UserProvider';
import clsx from 'clsx';
// import { Input, InputSearch, Section, Select } from './styles';
import { useEffect } from 'react';
import { isBibiUser } from '../../../utils/roles';
import DialogFornecedores from '../../../components/ModalFornecedores';
import { useLoading } from '../../../providers/LoadingProvider';
import {
  ActionButton,
  Actions,
  EmptyText,
  FilesContainer,
  FolderFileContainer,
  FolderHeader,
} from './styles';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { FolderService } from '../../../services/folder.service';
import { Assignment, FolderRounded, RemoveRedEye } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 'inherit',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center',
  },
  title: {
    fontSize: 36,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    letterSpacing: '-2.05px',
  },
  orangeSet: {
    backgroundColor: '#FE5D00',
    padding: '0rem 0rem 5rem 0rem',
  },
  whiteSet: {
    backgroundColor: '#FFFFFF',
    height: 0,
  },
  mainCards: {
    position: 'relative',
    zIndex: 50,
    top: '-4rem',
  },
  button: {
    height: '100%',
    padding: '.6rem 1.4rem',
    border: '1px solid #FFFFFF',
    fontWeight: 'bold',
    borderRadius: '10px',
    background: '#FFFFFF',
    cursor: 'pointer',
  },
  checkColor: {
    color: `${theme.palette.primary.main}!important`,
  },
  buttonSave: {
    width: '100%',
    backgroundColor: '#FE5D00',
    padding: '10px 0',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    cursor: 'pointer',
  },
}));

export function SupplierFiles() {
  const classes = useStyles();
  const { userState } = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [folders, setFolders] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [path, setPath] = useState([]);
  const { setLoading } = useLoading();

  const history = useHistory();
  const { folder } = useParams();

  async function getFiles(folder) {
    setFiles([]);
    setLoading(true);
    const data = await FolderService.getFilesByFolder(folder);
    setFiles(data);
    setLoading(false);
  }

  async function getFolders() {
    if (!userState.ID) {
      setAllFolders([]);
      return;
    }

    let data = [];
    if (allFolders.length > 0) {
      data = allFolders;
    } else {
      data = await FolderService.getBySupplier(userState.ID);
      setAllFolders(data);
    }

    if (folder) {
      await getFiles(folder);
      setPath(getFolderPath(data));
      setFolders(data.filter((item) => item.PASTA == folder));
    } else {
      setFolders(data.filter((item) => item.PASTA == null));
    }
  }

  useEffect(() => {
    getFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, folder]);

  function getFolderPath(allFolders, arr = [], parentFolder = null) {
    if (!folder) return;

    let newArr = [];

    if (arr.length == 0 && !parentFolder) {
      const folderFinded = allFolders.find((item) => item.ID == folder);
      newArr = [folderFinded];
      return getFolderPath(allFolders, newArr, folderFinded.PASTA);
    }

    if (arr.length > 0) {
      if (parentFolder) {
        const finded = allFolders.find((item) => item.ID == parentFolder);
        newArr = [finded, ...arr];
        return getFolderPath(allFolders, newArr, finded.PASTA);
      }

      if (!parentFolder) {
        return arr;
      }
    }
  }

  function handleOpenFolder(folder) {
    history.push(`/arquivos/${folder}`);
  }

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.container}>
        <Box
          className={clsx(`${classes.orangeSet} `, {
            fit: isBibiUser(userState?.tipo),
          })}
          textAlign="center"
        >
          <Box paddingTop={2} textAlign="center" className="header-div">
            <Typography
              className={classes.title}
              component="h1"
              variant="subtitle1"
            >
              ARQUIVOS
            </Typography>
          </Box>
        </Box>
        <Box className={`header-div ${classes.whiteSet}`}>
          <div style={{ width: '100%' }}>
            <Folder
              folders={folders}
              openFolder={handleOpenFolder}
              folder={folder}
              files={files}
              path={path}
            />
          </div>
        </Box>
      </Container>
      <DialogFornecedores
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </div>
  );
}

function Folder({ folders, openFolder, folder, files, path }) {
  const history = useHistory();
  const isEmpty = folders.length == 0 && files.length == 0;

  function handleClick(folder) {
    if (folder) {
      history.push(`/arquivos/${folder}`);
    } else {
      history.push(`/arquivos`);
    }
  }

  return (
    <section>
      <FolderHeader>
        <h3>
          <span onClick={() => handleClick()}>Home / </span>
          {path.map((item, index) => (
            <span
              key={item.ID}
              onClick={() => handleClick(item.ID)}
              style={{ color: index == path.length - 1 ? '#FE5D00' : 'black' }}
            >
              {item.NOME} /{' '}
            </span>
          ))}
        </h3>
      </FolderHeader>

      {isEmpty && !folder && (
        <EmptyText>
          Ainda não foram adicionados arquivos compartilhados com você.
        </EmptyText>
      )}
      {isEmpty && folder && <EmptyText>Está pasta está vazia</EmptyText>}

      <FilesContainer>
        {folders.map((folder) => (
          <FolderFile
            key={folder.ID}
            item={folder}
            type="folder"
            handleClick={() => openFolder(folder.ID)}
          />
        ))}
        {files.map((file) => (
          <FolderFile key={file.ID} item={file} type="file" />
        ))}
      </FilesContainer>
    </section>
  );
}

const iconParams = {
  htmlColor: '#ED6608',
  style: {
    height: 70,
    width: 70,
  },
};
function FolderFile({ item, type, handleClick }) {
  const { NOME } = item;

  function seeFile(event) {
    event.stopPropagation();
    window.open(`https://cxd.bibi.com:3636${item.CAMINHO}`, '_blank');
  }

  return (
    <FolderFileContainer>
      <div className="square" onClick={handleClick}>
        {type == 'folder' ? (
          <FolderRounded {...iconParams} />
        ) : (
          <Assignment {...iconParams} />
        )}

        <Actions>
          {type == 'file' && (
            <ActionButton onClick={(event) => seeFile(event)}>
              <RemoveRedEye
                htmlColor="#003407"
                style={{ height: 20, width: 20 }}
              />
            </ActionButton>
          )}
        </Actions>
      </div>
      <p>{NOME}</p>
    </FolderFileContainer>
  );
}
