import { useEffect, useState } from 'react';
import { useStyles } from './styles';

import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, useMediaQuery } from '@material-ui/core';

import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import BaseModal from '../BaseModal';
import { Input } from '../../pages/Admin/Suppliers/styles';
import { destroy, register, update } from '../../services/news';
import Swal from 'sweetalert2';
import styled from 'styled-components';

const SaveButton = styled.button`
  background-color: #FE5D00;
  padding: 10px 0;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  margin-top: 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 22px;
  margin-left: 22px;
  margin-right: 22px;
`;

export function CreateModal({ notice, open, onClose }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery('(max-width:900px)');

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [title, setTitle] = useState('');
  const [notificar, setNotificar] = useState(false);

  useEffect(() => {
    if (notice?.CONTEUDO) {
      const blocksFromHTML = convertFromHTML(notice.CONTEUDO);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      setEditorState(() => EditorState.createWithContent(state));
    } else {
      setEditorState(() => EditorState.createEmpty());
    }

    if (notice?.TITULO) setTitle(notice.TITULO);
  }, [notice]);

  const save = async () => {
    setLoading(true);
    try {
      if (notice?.ID) {
        await update(notice?.ID, {
          TITULO: title,
          CONTEUDO: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        });
      } else {
        await register({
          TITULO: title,
          CONTEUDO: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          NOTIFICAR: notificar,
        });
      }
      window.location.reload();
    } catch (e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Ocorreu um erro ao cadastrar a notícia. Tente novamente',
        confirmButtonText: 'Ok',
      });
    }
  };

  const deleteNotice = async (id) => {
    setLoading(true);
    try {
      await destroy(id);
      window.location.reload();
    } catch (e) {
      setLoading(false);
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  return (
    <BaseModal
      open={open}
      handleClose={() => onClose(false)}
      cardStyle={{ width: matches ? '95%' : '50%', minHeight: '80%' }}
    >
      <Box
        display={'flex'}
        justifyContent="space-between"
        alignItems={'center'}
      >
        <Typography component="h1" variant="body2">
          Cadastar nova noticia
        </Typography>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          {!notice?.ID && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '-8px',
              }}
            >
              <Checkbox
                classes={{
                  root: classes.checkColor,
                  checked: classes.checkColor,
                }}
                value={notificar}
                onChange={(e) => setNotificar(!notificar)}
              />
              <span style={{ marginTop: '4px' }}>Notificar fornecedores</span>
            </div>
          )}

          {notice?.ID && (
            <button
              onClick={() => deleteNotice(notice?.ID)}
              style={{
                backgroundColor: '#FE5D00',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '8px',
                color: 'white',
                cursor: 'pointer',
                opacity: loading ? 0.5 : 1,
              }}
            >
              Excluir
            </button>
          )}
        </div>
      </Box>

      <div style={{ marginTop: '1rem' }}>
        <label htmlFor="">Titulo</label>
        <Input
          defaultValue={notice?.TITULO ? notice?.TITULO : ''}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div style={{ marginTop: '1rem' }}>
        <label htmlFor="">Conteúdo</label>
        <Editor
          localization={{
            locale: 'pt',
          }}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'embedded',
              'image',
              'remove',
              'history',
            ],
          }}
          toolbarStyle={{
            border: 'none',
          }}
          editorStyle={{
            padding: '0.5em 1em',
            fontWeight: 'normal',
          }}
          wrapperStyle={{
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            minHeight: '350px',
            maxHeight: '400px',
            overflowY: 'scroll',
          }}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      </div>

      <SaveButton style={{ opacity: loading ? 0.5 : 1 }} disabled={loading} onClick={save}>Salvar</SaveButton>
    </BaseModal>
  );
}
